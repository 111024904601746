import React from 'react';
import Helmet from 'react-helmet';
import { withMixpanel } from 'gatsby-plugin-mixpanel'

import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import ContentHero from '../components/ContentHero';
import MasterMindBanner from '../components/MasterMindBanner';
import CategoryCard from '../components/CategoryCard';
import VideoOverlay from '../components/VideoOverlay';
import Page from '../layout/Page'

import styles from './content.module.scss';
import CONTENT from '../data/content';

class Index extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet title="Perspective Resources" defer={false} />
                <VideoOverlay />
                <Page>
                    {/*    Navigation */}
                    <NavigationBar lang='de' showFunnelLink={false} />
                    {/*    Content */}
                    {/*    Headline */}
                    {/*    Main */}
                    <div className={styles.main}>
                        {/*ContentHero*/}
                        <ContentHero headline="Auf Website einbinden"
                                     subHeadline="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua." />
                        <div className={styles.subHeadlineContainer}>
                            <span className={styles.subHeadline}>Gib deiner Webseite ein klares Ziel und steigere die Conversion Rate mit einem Funnel um über 50%</span>
                        </div>
                        <div className={styles.cardsContainer}>
                            {CONTENT.templateCards.map(card =>
                                <CategoryCard key={card.name}
                                              name={card.name}
                                              imgSrc={card.imgSrc}
                                              title={card.title['de']}
                                              description={card.description['de']}
                                              linkUrl={card.url}
                                              onClick={this.handleClick}/>
                            )}
                        </div>
                        <MasterMindBanner lang={'de'}/>
                    </div>
                    <Footer lang={'de'} />
                </Page>
            </React.Fragment>
        )
    }
}

export default withMixpanel()(Index)
